@import '../../styles/responsive.scss';

.sidebar {
    flex: 1;
    border-right: 0.5px solid rgb(230, 227, 227);
    min-height: 100vh;
    width: auto;
    background-color: #4e73df;
    background-image: linear-gradient(180deg, #152658 10%, #224abe 100%);

    @include md {
        display: none;
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
            width: 100%;
            margin: 1rem;
            padding: 0.5rem;
        }
    }

    hr {
        border-top: 0.5px solid rgba(255, 255, 255, 0.15);
        margin: 0 1rem 1rem;
    }

    .center {
        padding-left: 10px;


        @include md {
            padding-left: 0px
        }

        ul {

            list-style: none;
            margin: 0;
            padding: 0;

            .title {
                font-size: 10px;
                font-weight: bold;
                color: #ffffff79;
                margin-top: 15px;
                margin-bottom: 5px;


                @include md {
                    display: none;
                }

            }

            li {
                display: flex;
                align-items: center;
                padding: 5px;
                cursor: pointer;


                @include md {
                    display: flex;
                    padding: 0.4rem;
                    justify-content: center;
                }

                @include xxl {
                    flex-wrap: nowrap;
                }


                &:hover {
                    background-color: #ece8ff2a;
                }

                .icon {
                    font-size: 18px;
                    color: #6786e2;

                    @include md {
                        display: none;
                    }
                }

                span {
                    font-size: .9rem;
                    font-weight: 300;
                    color: #ffffff;
                    margin-left: 10px;


                    @include md {
                        font-size: .5rem;
                        font-weight: 200;
                        margin-left: 0px;
                        text-align: center;
                    }
                }
            }
        }
    }



    .bottom {
        display: flex;
        align-items: center;
        margin: 10px;

        .colorOption {
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid #7451f8;
            cursor: pointer;
            margin: 5px;

            &:nth-child(1) {
                background-color: whitesmoke;
            }

            &:nth-child(2) {
                background-color: #333;
            }
        }
    }




}


.nounderline {
    list-style: none;
    text-decoration: none;
    color: inherit;
}



.disabled {
    pointer-events: none; //This makes it not clickable
    opacity: 0.6; //This grays it out to look disabled
}