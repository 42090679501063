.avansRapor {
    display: flex;
    width: 100%;

    .avansRaporContainer {
        flex: 6;
    }

    .button {
        text-transform: inherit !important;
    }
}



.selectSectionAvansRapor {
    padding: 1rem;
    display: flex;
    align-items: center;
    max-width: 100%;
}

.raporSection{
    padding: 1rem;
}