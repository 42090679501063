@import '../../styles/responsive.scss';

.avansKapama {
    display: flex;
    width: 100%;

    .avansKapamaContainer {
        flex: 6;
    }

    .form {
        padding: 5rem;

        @include sm {
            padding: 1rem;
            display: flex;
            flex-direction: column;
        }

    }

}

.button {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    align-items: center;
}