.projeEkle{
    display: flex;
    width: 100%;
    .projeEkleContainer{
        flex: 6;
    }

    .form{
        padding: 5rem;
    }
}


