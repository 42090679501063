.odemeVeriGirisi{
    display: flex;
    width: 100%;
    .odemeVeriGirisiContainer{
        flex: 6;
    }

    .selectSection {
        padding: 1rem;
        display: flex;
        align-items: center;
        max-width: 100%;
    }

    .button {
        text-transform: inherit !important;
    }

}


