@import '../../styles/responsive.scss';
.butceIslemleri {
    display: flex;
    width: 100%;

    .butceContainer {
        flex: 6;
    }

    
.button {
    text-transform: inherit !important;
}

.buttons {

    .ekleButton {
        margin-left: 0.5rem;
    }

}

.formControl {
    display: flex;
    flex-wrap: wrap;
    
    .item {
        flex: 1 1;
        padding: 20px;
        border: 1px solid #ccc;
      }
}

}

.selectSectionButceIslemleri {
    padding: 1rem;
    display: flex;
    align-items: center;
    max-width: 100%;
    
    @include sm {
        padding: 1rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        max-width: 100%;
    }
}



