@import '../../styles/responsive.scss';
.table{
    .cellWrapper{
        display: flex;
        align-items: center;

        .image{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 10px;
            object-fit: cover;
        }
    }


    .status{
        padding: 5px;
        border-radius: 5px;

        &.Approved{
            color:green;
            background-color: rgba(0, 128, 0, 0.151);
        }
        
        &.Pending{
            color:goldenrod;
            background-color: rgba(189, 189, 3, 0.103);
        }
    }
}

.datatable{
    height: 600px;
    padding: 20px;
    width: 81rem;
    

    @include md {
        height: 600px;
        padding: 20px;
        width: 81rem;
    }
    @include lg {
        height: 600px;
        padding: 20px;
        width: 81rem;
    }

    @include xl {
        height: 600px;
        padding: 20px;
        width: 100rem;
    }
    @include xxl {
        height: 600px;
        padding: 20px;
        width: 100rem;
    }


    .cellWithImg{
        display: flex;
        align-items: center;

        .cellImg{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 20px;
        }

   

    }


    .cellWithStatus{
        padding: 5px;
        border-radius: 5px;

        &.active{
            color: green;
            background-color: rgba(0,128,0,0.05);
        }

        &.pending{
            color: goldenrod;
            background-color: rgba(255,217,0,0.05);
        }

        &.passive{
            color: crimson;
            background-color: rgba(255,0,0,0.05);

        }
    }

    .cellAction{
        display: flex;
        align-items: center;
        gap: 15px;

        .viewButton{
            padding: 2px 5px;
            border-radius: 5px;
            color: darkblue;
            cursor: pointer;
        }
        .saveButton{
            padding: 2px 5px;
            border-radius: 5px;
            color: green;
            cursor: pointer;
        }
    }
}


.butceHeader{
    background-color: lightgreen;
}
.farkHeader{
    background-color: rgb(206, 97, 97);
}

.gerceklesenHeader{
    background-color: rgb(15, 53, 179);
    color: #ffffff;
}
.odemeHeader{
    background-color: rgb(193, 224, 55);
}

.MuiDataGrid-cell--textLeft {
    justify-content: center !important;
  }