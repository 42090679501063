@import '../../styles/responsive.scss';
.home{
  display: flex;

  .button {
    text-transform: inherit !important;
}

  .homeContainer{

    
    flex: 6;


    .widgets, .charts{
      display: flex;
      padding: 20px;
      gap: 20px;

      @include lg {
        display: flex;
        flex-wrap: wrap;
        padding: 1rem;
        justify-content: center;
        flex-direction: column;
    }
    }

    .charts{
      padding: 5px 20px;
  }
    .listContainer{
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 20px;


      @include sm {
        display: none;
    }

      .listTitle{
        font-weight: 500;
        margin-bottom: 15px;
        color:gray;
      }
    }
  }
}

.selectSectionHome {
  padding: 1rem;
  display: flex;
  align-items: center;
  max-width: 100%;

  
  @include sm {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 100%;
}

}