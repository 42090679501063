.butceGenelRapor{
    display: flex;
    width: 100%;
    .butceGenelRaporContainer{
        flex: 6;
    }

    .raporSection{
        padding: 1rem;
    }
}